import request from '@/utils/request';

const test = location.origin.indexOf("test-asa.qiumo.net.cn") >= 0;
// 供应商
const ipBaseURL = test ? 'http://10.12.21.173:8086/marketapi/' : "https://as.qiumo.net.cn/marketapi/"

// 传页面加载时间
export const cacheRecord = (data) => {
  return request({
    ipBaseURL,
    method: "GET",
    url: "/visit/cacheRecord",
    params: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
  })
}

// 统计有效手机号输入人数
export const embedding = (data) =>
  request({
    url: "/visit/embedding",
    method: "POST",
    ipBaseURL,
    data,
  });
