import { cacheRecord } from "@/api/utils.js";

export default {
  created() {
    if (process.env.NODE_ENV !== "development") {
      window.addEventListener("load", function () {
        var performance =
          window.performance ||
          window.msPerformance ||
          window.webkitPerformance;
        if (performance) {
          let { domContentLoadedEventEnd = 0, navigationStart = 0 } =
            performance.timing;

          const visitLoadTime = domContentLoadedEventEnd - navigationStart;

          cacheRecord({
            t: visitLoadTime,
            v: decodeURIComponent(window.location.href),
          }).catch((err) => console.log(err));
        }
      });
    }
  },
}