<template>
  <div class="container">
    <img
      class="bg"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/fusion/LTVideoRingtone/top.png"
    />
    <div class="container-cont">
      <div class="form">
        <img
          class="step"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/fusion/LTVideoRingtone/step.gif"
        />
        <van-field
          v-model.trim="phone"
          type="tel"
          @input="phoneChange"
          maxlength="11"
          placeholder="请输入联通号码"
        />
        <img
          class="submit"
          @click="phoneChange"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/fusion/LTVideoRingtone/submit_youxianqiang.gif"
        />
        <van-checkbox
          checked-color="#ee0300"
          v-model="checked"
          icon-size="12px"
        >
          我已阅读并同意<span class="book" @click.stop="showDialog"
            >隐私条款</span
          >
        </van-checkbox>
      </div>
      <img
        class="bg"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/fusion/LTVideoRingtone/card1.png"
      />
      <img
        class="bg"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/fusion/LTVideoRingtone/card2.png"
      />
      <img
        class="bg"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/fusion/LTVideoRingtone/card3.png"
      />
      <img
        class="bg"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/fusion/LTVideoRingtone/card4_1.png"
      />
    </div>
  </div>
</template>

<script>
import { hnPhoneCheck } from "@/api/qiuMoFusion/index";
import { embedding } from "@/api/utils.js";
import tailJumpMixin from "@/utils/tailJump.js";
import { liuliangguo } from "@/utils/common";
import performance from "@/mixin/performance.js";

export default {
  mixins: [tailJumpMixin, performance],
  data() {
    return {
      phone: "",
      productNo: "",
      checked: false,
    };
  },
  created() {
    const stroagePhone = localStorage.getItem("phone") || "";
    this.phone = stroagePhone;
  },
  methods: {
    async phoneChange() {
      let filterPhone = /^1[3456789]\d{9}$/;
      if (!filterPhone.test(this.phone)) return;
      this.$toast({
        message: "正在为您挑选合适产品~",
      });
      localStorage.setItem("phone", this.phone);
      const { data = "" } = await hnPhoneCheck(this.phone);
      if (!data || data == "") return;
      try {
        if (process.env.NODE_ENV !== "development") {
          await embedding({
            embeddingCode: "o.phone",
            clickNum: "1",
            form: "LIANTONGRONGHE",
            phone: this.phone,
            phoneProvince: data,
          });
        }
      } finally {
        const { origin = "", search = "", pathname = "" } = location;
        const ip = origin + "/pages";
        const linkSearch = `${
          !!search ? `${search}&` : "?"
        }from=LIANTONGRONGHE&phone=${this.phone}&rootUrl=${origin}${pathname}`;

        if (data.indexOf("联通") >= 0) {
          // if (data.indexOf("浙江") >= 0) {
          //   window.location.replace(
          //     `${ip}/fusion/LTVideoRingtone/zhejiang/videoRingtone25${linkSearch}`
          //   );
          // } else if (data.indexOf("安徽") >= 0) {
          //   window.location.replace(
          //     `${ip}/fusion/LTVideoRingtone/anhui/videoRingtone26${linkSearch}`
          //   );
          // } else if (data.indexOf("四川") >= 0) {
          // window.location.replace(
          //   `${ip}/fusion/LTVideoRingtone/sichuan/hotVip${linkSearch}`
          // );
          // } else if (data.indexOf("上海") >= 0) {
          //   window.location.replace(
          //     `${ip}/fusion/LTVideoRingtone/shanghai/videoRingtone25${linkSearch}`
          //   );
          // } else
          if (data.indexOf("辽宁") >= 0) {
            window.location.replace(
              `${ip}/fusion/LTVideoRingtone/liaoning/microdrama25_version${linkSearch}`
            );
          } else {
            // 其它省份联通号码
            liuliangguo("103810", this.phone, "联通视频彩铃");
          }
        } else {
          liuliangguo("103810", this.phone, "联通视频彩铃");
        }
      }
    },
    showDialog() {
      this.$dialog.alert({
        messageAlign: "left",
        title: "个人信息保护授权协议",
        message: `尊敬的客户：
您好！
根据《中华人民共和国网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在我公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理店等）办理移动电话（含无线上网卡）、固定电话、家庭宽带入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效身份证件原件并进行查验、登记。登记信息包括姓名或名称、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效身份证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。
为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外提供客户个人信息：
（1）事先获得客户的明确授权；
（2）根据有关的法律法规要求；
（3）按照相关司法机关和/或政府主管部门的要求；
（4）为维护社会公众的利益所必需且适当；
（5）为维护我公司或客户的合法权益所必需且适当。`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #fb203f;
  min-height: 100%;
  overflow: auto;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 100px;

  .bg {
    width: 100%;
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
  }

  &-cont {
    position: relative;

    .form {
      background-image: linear-gradient(to bottom, #f6164e, #fd5555);
      padding-top: 14px;

      .step {
        position: absolute;
        left: 143px;
        z-index: 1;
        top: -46px;
        width: 298px;
        pointer-events: none;
      }

      ::v-deep .van-cell {
        width: 604px;
        height: 84px;
        background-color: #ffffff;
        border-radius: 42px;
        margin: 0 auto;
        overflow: hidden;
        box-sizing: border-box;

        &.input-code {
          margin-top: 45px;

          .code {
            width: 229px;
            text-align: center;
            color: #e13449;
            font-size: 30px;
            border-left: solid 3px #e13449;
          }
        }

        .van-field__body {
          height: 100%;

          input {
            height: 100%;
          }
        }

        &::after {
          border: none;
        }

        .van-field__control {
          text-align: center;
          font-size: 28px;

          &::-webkit-input-placeholder {
            text-align: center;
            font-size: 28px;
            color: #a8a8a8;
          }
        }
      }

      .submit {
        width: 604px;
        display: block;
        margin: 15px auto 0;
        // animation: identifier 1s ease-in-out infinite alternate;

        @keyframes identifier {
          0% {
            transform: scale(1);
          }

          25% {
            transform: scale(0.9);
          }

          50% {
            transform: scale(1);
          }

          75% {
            transform: scale(0.9);
          }
        }
      }

      ::v-deep .van-checkbox {
        width: auto;
        justify-content: center;
        margin-top: -44px;
        padding-bottom: 40px;

        &__label {
          font-size: 22px;
          margin-left: 6px;
          color: #ffe5e5;
        }
      }
    }
  }
}
</style>
